/*
 /$$
| $$
| $$        /$$$$$$         /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$
| $$       |____  $$       /$$__  $$ |____  $$ /$$__  $$|____  $$
| $$        /$$$$$$$      | $$  \ $$  /$$$$$$$| $$  \__/ /$$$$$$$
| $$       /$$__  $$      | $$  | $$ /$$__  $$| $$      /$$__  $$
| $$$$$$$$|  $$$$$$$      | $$$$$$$/|  $$$$$$$| $$     |  $$$$$$$
|________/ \_______/      | $$____/  \_______/|__/      \_______/
                          | $$
                          | $$
                          |__/

   Entry point of sass styles.

*/
@import 'bulma/overrides';
@import 'node_modules/bulma/bulma';
@import 'utilities/print';

html, body{
  overflow-x: hidden;
}

[v-cloak] {
  display: none;
}

dt {
  font-weight: bold;
}

dd > dl {
  margin-left: 1em;
  padding-left: 1em;
  border-left: 1px solid $grey-lighter;
}

.is-strikethrough {
  text-decoration: line-through;
}

.is-red {
  color: $red !important;
}

.title.is-inverted {
  color: invert($black);
}

.is-bold {
  font-weight: 600;
}

.is-not-underline:hover {
  border-bottom: none !important;
}


.card-footer-item.notification {
  border-radius: 0;
}

.select .is-disabled {
  opacity: 0.6;
}

.account-menu{
  margin-bottom: 0.25em!important;
}

.account-menu ul li:last-child a {
  padding-right: 0;
}

.natpro header.hero picture {
  background: linear-gradient(to bottom, $blue 50%, $lighter-blue 50%);
  padding: 20px 0 20px 0;
  margin: 0;
  text-align: center;
}

.natpro header.hero.is-primary {
  background-color: $lighter-blue;
}

.natpro header.is-primary .hero-body {
  padding-top: 0;
  padding-bottom: 20px;
}

.natpro header.is-dark {
  padding: 0;
  border-bottom: 2px dashed $lighter-blue;
}

.natpro header.is-dark .hero-body {
  padding: 20px;
}

.natpro header .hero-foot {
  background-color: $darker-blue;
  padding: 20px;
}

.natpro section.block:nth-child(odd) {
  background-color: $table-row-even-background;
}

.is-para-header-content-menu li:first-child a {
  padding-left: 0;
}

@media screen and (max-width: 768px) {

  .are-horizontal-tabs {
    animation-duration: 1.25s;
    animation-fill-mode: both;
    animation-name: bounceInRight;
  }


}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.has-overlay-title > h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.hero .has-background-image {
  background-size: cover;
}

.box.is-borderless {
  box-shadow: none !important;
}



.notification.is-info a {
  color: #fff;
}

.__product-card {
  display:flex;
  flex-direction:column;
  align-self: stretch;
  justify-content: space-between;
}

.subbranded-background #public-title{
  background-color: transparent !important;
  min-width:50%;
  font-weight: bold !important;
  font-size: 3em !important;
  margin-bottom: 1.5em !important;
  padding-bottom: 0px !important;
}
.subbranded-background #public-subtitle{
  color: white;
  width:50%;
}

.subbranded-background .hero-body{
  background-color: #000;
  opacity:0.4;
}

#__mainLogoHeader{
  padding: 1.5em !important;
}
.subbrand-showcase{
  flex-direction: row !important;
}
.subbrand-showcase .main{
  width: 60%;
}
.subbrand-showcase .by{
  height: 100px;
}
.is-homepage-1, .is-homepage-0, .is-homepage-2, .is-homepage-3{
  background-color: #51cd8f !important;
}
.is-homepage-1 #public-title{
  background-color: transparent !important;
  min-width:50%;
  font-weight: bold !important;
  font-size: 3em !important;
  margin-bottom: 1.5em !important;
  margin-top: 1em !important;
  padding-bottom: 0px !important;
}
.is-homepage-1 #public-subtitle{
  color: white;
  width:50%;
}
.is-homepage-1 hr{
  border: 1px solid #fff !important;
}
.is-homepage-0 #public-title{
  background-color: transparent !important;
  min-width:50%;
  font-weight: bold !important;
  font-size: 3em !important;
  margin-bottom: 1.5em !important;
  margin-top: 1em !important;
  padding-bottom: 0px !important;
}
.is-homepage-0 #public-subtitle{
  color: white;
  width:50%;
}
.is-homepage-2 hr{
  border: 1px solid #fff !important;
}
.is-homepage-2 #public-title{
  background-color: transparent !important;
  min-width:50%;
  font-weight: bold !important;
  font-size: 3em !important;
  margin-bottom: 1.5em !important;
  margin-top: 1em !important;
  padding-bottom: 0px !important;
}
.is-homepage-2 #public-subtitle{
  color: white;
  width:50%;
}
.is-homepage-2 hr{
  border: 1px solid #fff !important;
}
.is-homepage-3 #public-title{
  background-color: transparent !important;
  min-width:50%;
  font-weight: bold !important;
  font-size: 3em !important;
  margin-bottom: 1.5em !important;
  margin-top: 1em !important;
  padding-bottom: 0px !important;
}
.is-homepage-3 #public-subtitle{
  color: white;
  width:50%;
}
.is-homepage-3 hr{
  border: 1px solid #fff !important;
}
.__specials_ticker{
  padding: 3em !important;
}
#__principleStatement{
  padding: 3em !important;
  font-size: 1.15em !important;
}
#__principleStatement h3{

  font-size: 3 !important;
}
#__blogRoll{
  padding: 3em !important;
  font-size: 1.15em !important;
}
#__blogRoll h3{
  font-size: 2.2 !important;
}
@import 'brutal-candycane'
