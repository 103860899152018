/**
  *
  * Colors from https://coolors.co/0096ff-5fd398-363537-ef2d56-ed7d3a
  */

  #__vidRoll, #__vidPlayer, #__vidList {
    font-size: 1.5em;
    font-weight: 200;
    padding: 1em;
    /*background: #363537;
    color: #fff;*/
}

#__vidRoll>h3, #__vidPlayer h2, #__vidList h2 {
    font-size: 2.2em;
    /*color: #fff;*/
    font-weight: 100;
    margin-bottom: 0.5em;
    /*text-align: right;*/
}

#__vidPlayer h2 {
    text-align: left;
}

#__vidPlayer {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
}
#__vidPlayer>.player,#__vidPlayer>.player>figure {
    width: 60vw;
}

#__vidPlayer>.player {
    margin:0 1em 1em 1em;
}

#__vidPlayer>.player>figure {
    height: 35vw;
    margin: 0 0 1em 0;
}

#__vidPlayer>aside.mentionned {
    overflow-x: auto;
}
#__vidPlayer>aside.mentionned>h3 {
    font-size: 1.6em;
    color: #51cd8f;
}

#__vidPlayer>aside.mentionned>ul {
    
    display: flex;
    flex-direction: row;
    -webkit-overflow-scrolling: touch;
    flex-wrap: unset;
    align-items: center;
}

#__vidPlayer>aside.mentionned>ul>li {
    
    width: 25vw;
    margin: 1em;
    flex-shrink: 0;
}

#__vidPlayer>aside.mentionned>ul>li:first-child {
    margin-left: 0;
}

#__vidRoll>h3 strong, , #__vidList>h3 string {
    color: #51cd8f;
}

#__vidPlayer>.player a, #__vidRoll>ul>li>a, #__vidList>ul>li>a {
    color: #008eff;
    text-decoration: underline;
    text-decoration-color: rgba(0, 142, 255, 0.2);
}

#__vidRoll>ul>li>a, #__vidList>ul>li>a {
    
    display: flex;
    flex-direction: column;
    line-height: 1.25em;
}

#__vidRoll a:hover, #__vidPlayer a:hover, #__vidList a:hover {
    color: #008eff;
}

#__vidRoll li, #__vidList li {
    margin-bottom: .5em;
    letter-spacing: -0.025em;
}

#__vidList>ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

#__vidRoll>ul {
    display: flex;
    -webkit-overflow-scrolling: touch;
    flex-wrap: unset;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
}

#__vidRoll>ul>li, #__vidList>ul>li {
    width: 20vw;
    flex-shrink: 0;
    text-align: center;
}

#__vidRoll>ul>li {
    margin-right: 1em;
}


#__vidRoll>ul>li:last-child {
    margin-right: 1em;
    align-self: center;
}

#__vidRoll>ul>li>a>img, #__vidList>ul>li>a>img {
    width: 100%;
    margin-bottom: 0.5em;
}


@media only screen and (max-width: 1023px) {
    #__vidRoll>ul>li, , #__vidList>ul>li {
        width: 35vw;
    }

    #__vidPlayer>.player,#__vidPlayer>.player>figure {
        width: 100%;
    }
    #__vidPlayer>.player>figure {
        height: 60vw;
    }
    #__vidPlayer > .player {
        margin: 0 0 1em 0;
    }
    
    #__vidPlayer {
        display:flex;
        flex-direction: column;
    }

    #__vidPlayer>aside.mentionned {
        width: 100%;
    }
    
    #__vidPlayer>aside.mentionned>ul>li {
        width: 40vw;
        margin: 1em;
    }
    
    #__vidPlayer>aside.mentionned>ul>li:first-child {
        margin-left: 0;
    }
}

@media only screen and (max-width: 768px) {
    
    #__vidPlayer>.player,#__vidPlayer>.player>figure {
        width: 100%;
    }
    #__vidPlayer>.player>figure {
        height: 40vh;
    }
    #__vidPlayer>aside.mentionned>ul>li {
        width: 75vw;
    }
}