
/**
  *
  *  Blogroll
  * Colors from https://coolors.co/0096ff-5fd398-ffe74c-ff5964-ffffff
  */

  #__blogRoll {
    font-size: 1.5em;
    font-weight: 200;
    padding: 1em;
    /*background: #FF5964;*/
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#__blogRoll>img {
    margin: 0 2em;
  }

#__blogRoll h3 {
    font-size: 2.2em;
    /*color: #fff;*/
    color: #363636;
    font-weight: 100;
    margin-bottom: 0.5em;
}

#__blogRoll h3 strong {
    /*color: #FFE74C*/
    color: #51cd8f;
}

#__blogRoll a {
    /*color: #fff;*/
    text-decoration: underline;
    text-decoration-color: rgba(0, 142, 255, 0.2);
    color: #008eff;
}

#__blogRoll a:hover {
    color: #008eff;
}

#__blogRoll li {
    margin-bottom: .5em;
    letter-spacing: -0.025em;
}

#__blogRoll figcaption {
    transform: rotate(90deg);
    font-size: 2em;
    font-weight: 600;
}

#__blogRoll ul:first-child li:last-child {
    margin-top: 1.5em;
    margin-bottom: 0;
} 


@media only screen and (max-width: 1023px) {
    #__blogRoll {
        justify-content: left;
        flex-direction: column-reverse;
    }

    #__blogRoll h3 {
        font-size: 2em;
        margin: 1em 0;
    }

    #__blogRoll li {
        letter-spacing: -0.025em;
        font-size: 1em;
        margin-bottom: 1em;
    }

    #__blogRoll li:last-child {
        margin-top: 1.5em;
    }
}