/*
    Bulma overrides
 */
// Take a slightly darkened blue as our primary blue color
$blue: #0086E6;
$green: #51CD8F;
$orange: #E66000;
$purple: #E60086;
$red: #E60013;
$yellow: #E6D200;
$turquoise: #00E6D2;
$white: #fff;

$body-background: #fff;
$primary: $blue;

// Custom colors
$lighter-blue: #0077CC;
$darker-blue: #265A7F;
