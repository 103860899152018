
/**
  *
  * Statement of principles
  * Colors from https://coolors.co/0096ff-5fd398-f7fff6-bcebcb-87d68d
  *
*/
#__principleStatement {
    font-size: 1.5em;
    font-weight: 200;
    padding: 1em;
    /*background-color: #F7FFF6;*/
    color: #363636;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  #__principleStatement>img {
    margin: 0 2em;
  }
  
  
  #__principleStatement h3 {
    font-size: 2em;
    /*color: #363636;*/
    font-weight: 100;
    margin: 0.5em 0;
  }
  
  
  #__principleStatement h3 strong {
    color: #51cd8f
  }
  
    #__principleStatement .content {
        hyphens: auto;
        column-count: 3;
        column-gap: 1.5em;
        text-align: justify;
        color: #363636;
    }
  
    #__principleStatement p:first-letter {
        font-family: cursive;
    }
  
    #__principleStatement .content  .logo {
        margin-top: -0.5em;
    }
  
    #__principleStatement .content .logo > img{
        width: 1.5em;height:1.5em;
    }

    @media only screen and (max-width: 1023px) {
        #__principleStatement {
            justify-content: left;
            flex-direction: column-reverse;
    }
  
        #__principleStatement .content {
            column-count: 1;
            font-size: 0.75em;
        }
  
       
  
    }