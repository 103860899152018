
/**
 * Header 2.0
 *
*/
#__header-nav {
    display: flex;
    flex-direction: column;
    margin: 0 -10px 0 -10px;
  }
  
  #__mainLogoHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4em 2em;
  }
 
  #__mainLogoHeader.has-subbrand {
    padding: 2em 2em;
  }

  #__mainLogoHeader>a, #__mainLogoHeader>.subbrand-showcase {
    width: 25vw
  }
  

  #__mainLogoHeader>.subbrand-showcase {
    display: flex;
    flex-direction: column;
  }

  #__mainLogoHeader>.subbrand-showcase>a.by {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: unset;
    justify-content: end;
    align-items: center;
    margin-top: -1em;
  }

  #__mainLogoHeader>.subbrand-showcase>a.by>span {
    margin-left: auto;
    margin-right: 0.125em;
    transform: rotate(-90deg);
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 800;
    color: #dbdbdb;
  }
  

  #__mainLogoHeader>.subbrand-showcase>a.by>img {
    width: 10vw;
  }
  
  
  #__mainLogoHeader .__headerControlsSearchRow>.control {
    width: 30vw;
    margin-bottom: 0;
    margin-right: 1em;
  }
  
  #__mainLogoHeader>.__headerControls {
    display: flex;
    flex-direction: column;
  }
  
  #__mainLogoHeader .__headerControlsContactRow>ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  
  #__mainLogoHeader .__headerControlsContactRow>ul>li {
    margin: 0 0 1em 1.2em;
    font-size: 1.1em;
  }
  
  
  #__mainLogoHeader .__headerControlsSearchRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: right;
  }
  
  /**
  * Colors from https://coolors.co/0086e6-51cd8f-011627-fdfffc-2ec4b6
  */
  #__superHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #51cd8f;
    color: #fff;
    padding: 0.5em 2em 0.5em 2em;
  }
  
  #__superHeader>ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1.1em;
    font-weight: 200;
    align-items: center;
  }
  
  #__superHeader>ul>li:not(:first-child) {
    margin-left: 1em;
  }
  
  #__superHeader a {
    color: #fff;
    text-decoration: underline;
  }
  
  #__superHeader .__withIcon {
    display: flex;
    flex-direction: row;
    flex-wrap: unset;
    align-items: center;
  }
  
  #__superHeader .__withIcon>i {
    margin-right: 0.5em;
  }
  
  #__superHeader sup,
  sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }
  
  #__superHeader sub {
    top: 0.4em;
  }
  
  
  #__productMenu {
    background-color: #f5f5f5;
    padding: 2em 2em;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
  
  #__productMenu>ul {
    display: flex;
    font-size: 1.5em;
    flex-direction: row;
    flex-wrap: unset;
  }
  
  #__productMenu>ul>li {
    flex-shrink: 0;
  
  }
  
  #__productMenu>ul>li:not(:first-child) {
    margin-left: 1.5em;
  }
  
  #__productMenu a {
    color: #011627;
  }
  
  
  @media only screen and (max-width: 768px) {
  
    #__header-nav>section,
    #__mainLogoHeader .__headerControls #__mainLogoHeader .__headerControlsSearchRow {
      flex-direction: column;
      width: 100%;
    }
  
    #__mainLogoHeader .__headerControlsContactRow>ul {
      margin-top: 1em;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    #__mainLogoHeader .__headerControlsSearchRow>.control,
    #__mainLogoHeader .__headerControlsSearchRow>.button {
      width: 100%;
      margin-bottom: 1em;
      margin-right: 0;
    }
  
    #__mainLogoHeader>a, #__mainLogoHeader>.subbrand-showcase{
      width: 100%;
    }

    #__mainLogoHeader>.subbrand-showcase>a.by>img {
      width: 30vw;
    }
  
    #__superHeader {
      font-size: 0.8em;
      flex-direction: column;
      align-items: unset;
    }
  
    #__superHeader>ul {
      flex-direction: column;
      align-items: unset;
    }
  
    #__superHeader>ul:not(:first-child) {
      margin-top: 0.5em;
      border-top: 0.5px solid white;
      padding-top: 0.5em;
    }
  
    #__superHeader>ul>li:not(:first-child) {
      margin-left: 0;
    }
  
    #__productMenu {
      margin-right: 20px;
    }
  
    #__productMenu>ul>li:last-child {
      padding-right: 1em;
    }
  
  }
