/**
 *
 Categories box
 *
*/

.__category_grid {
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    justify-content: center;
    margin-bottom:4em;
  }
  
  .__scrolling.__category_grid {
    -webkit-overflow-scrolling: touch;
    flex-wrap: unset;
    overflow-x: auto;
  }
  
  .__category_grid_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    flex-shrink: 0;
  }

  .__scrolling>.__category_grid_item {
    height: 29vw;
    width: 29vw;
}
  
  .__category_grid_item>.title {
    text-transform: uppercase;
    font-size: 2.5em;
    color: white;
    font-weight: 200;
    padding: 0.25em;
    text-align: center;
  }
  
  @media only screen and (max-width: 1023px) {
    .__category_grid_item>.title {
      font-size: 1.5em;
    }
    .__scrolling .__category_grid_item>.title {
      font-size: 2.25em;
    }
    .__scrolling>.__category_grid_item {
        height: 55vw;
        width: 55vw;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .__category_grid_item>.title {
      font-size: 1.25em;
    }
  
    .__scrolling .__category_grid_item>.title {
      font-size: 2em;
    }
  
    
    .__scrolling>.__category_grid_item {
        height: 75vw;
        width: 75vw;
    }
  }
