.is-visible-print {
  display: none !important;
}

@media print {
  .is-hidden-print {
    display: none !important;
  }

  .is-visible-print {
    display: block !important;
  }

  .hero-body {
    padding: 20px 10px;
  }

  .product-content {
    p {
      orphans: 4;
    }

    .content h1:not(:first-child), .content h2:not(:first-child), .content h3:not(:first-child) {
      margin-top: 20px;
    }
  }

  .is-image-print {
    height: 300px !important;
    width: 300px !important;
    padding-top: 0 !important;
    margin: 0 auto;
  }
}