.__specials_ticker {
    display: flex;
    flex-direction: row;
    -webkit-overflow-scrolling: touch;
    flex-wrap: unset;
    overflow-x: auto;
    align-items: center;
    padding: 2em 2em;
    /*background-image: url('/img/specials-bg-pattern.svg');
    border-bottom: 1em solid #FF3366;
    border-top: 1em solid #FF3366;*/
  }
  
  .__specials_ticker>.__specials_ticker_card {
    width: 20vw;
    margin: 0 1em;
    flex-shrink: 0;
    /*box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.6);*/
    align-self:stretch;
    display:flex;
  }
  
  .__specials_ticker>.__specials_ticker_infocard>h3 {
    width: 15vw;
    margin: 0 1em 0 0;
    flex-shrink: 0;
    text-align: center;
    font-size: 2em;
    line-height: 1em;
    font-weight: bolder;
    color: #F6F7F8;
    background-color: #51cd8f;
    padding: 2em 0.4em;
    /*box-shadow: 0px 0px 10px 3px rgba(0, 142, 255, 0.2);*/
  }
  
  @media only screen and (max-width: 1023px) {
    .__specials_ticker>.__specials_ticker_card {
        width: 35vw;
    }
  
    .__specials_ticker>.__specials_ticker_infocard>h3 {
        width: 25vw;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .__specials_ticker>.__specials_ticker_card {
        width: 65vw;
    }
  
    .__specials_ticker>.__specials_ticker_infocard>h3 {
        width: 45vw;
    }
  }